function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { warn } from '@algolia/autocomplete-shared';
import { defaultTemplates } from './templates';
function defaultTransformResponse(response) {
  var _renderingContent, _renderingContent$red;
  return (_renderingContent = response.renderingContent) === null || _renderingContent === void 0 ? void 0 : (_renderingContent$red = _renderingContent.redirect) === null || _renderingContent$red === void 0 ? void 0 : _renderingContent$red.url;
}
function defaultOnRedirect(redirects, _ref) {
  var _item$urls;
  var event = _ref.event,
    navigator = _ref.navigator,
    state = _ref.state;
  var item = redirects[0];
  var itemUrl = item === null || item === void 0 ? void 0 : (_item$urls = item.urls) === null || _item$urls === void 0 ? void 0 : _item$urls[0];
  if (!itemUrl) {
    return;
  }
  if (event.metaKey || event.ctrlKey) {
    navigator.navigateNewTab({
      itemUrl: itemUrl,
      item: item,
      state: state
    });
  } else if (event.shiftKey) {
    navigator.navigateNewWindow({
      itemUrl: itemUrl,
      item: item,
      state: state
    });
  } else {
    navigator.navigate({
      itemUrl: itemUrl,
      item: item,
      state: state
    });
  }
}
function getOptions(options) {
  return _objectSpread({
    transformResponse: defaultTransformResponse,
    templates: defaultTemplates,
    onRedirect: defaultOnRedirect
  }, options);
}
function getRedirectData(_ref2) {
  var _redirectUrlPlugin$da;
  var state = _ref2.state;
  var redirectUrlPlugin = state.context.redirectUrlPlugin;
  return (_redirectUrlPlugin$da = redirectUrlPlugin === null || redirectUrlPlugin === void 0 ? void 0 : redirectUrlPlugin.data) !== null && _redirectUrlPlugin$da !== void 0 ? _redirectUrlPlugin$da : [];
}
export function createRedirectUrlPlugin() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _getOptions = getOptions(options),
    transformResponse = _getOptions.transformResponse,
    templates = _getOptions.templates,
    onRedirect = _getOptions.onRedirect;
  function createRedirects(_ref3) {
    var _state$context$redire, _state$context$redire2;
    var results = _ref3.results,
      source = _ref3.source,
      state = _ref3.state;
    var redirect = {
      sourceId: source.sourceId,
      urls: results.map(function (result) {
        return transformResponse(result);
      }).filter(function (url) {
        return url !== undefined;
      })
    };
    var redirects = (_state$context$redire = (_state$context$redire2 = state.context.redirectUrlPlugin) === null || _state$context$redire2 === void 0 ? void 0 : _state$context$redire2.data) !== null && _state$context$redire !== void 0 ? _state$context$redire : [];
    var existingRedirectIndex = redirects.findIndex(function (r) {
      return r.sourceId === source.sourceId;
    });
    if (existingRedirectIndex !== -1) {
      if (redirect.urls.length === 0) {
        redirects.splice(existingRedirectIndex, 1);
      } else {
        redirects[existingRedirectIndex] = redirect;
      }
    } else if (redirect.urls.length > 0) {
      redirects.push(redirect);
    }
    return redirects;
  }
  return {
    name: 'aa.redirectUrlPlugin',
    subscribe: function subscribe(_ref4) {
      var onResolve = _ref4.onResolve,
        onSelect = _ref4.onSelect,
        setContext = _ref4.setContext,
        setIsOpen = _ref4.setIsOpen;
      onResolve(function (_ref5) {
        var results = _ref5.results,
          source = _ref5.source,
          state = _ref5.state;
        setContext(_objectSpread(_objectSpread({}, state.context), {}, {
          redirectUrlPlugin: {
            data: createRedirects({
              results: results,
              source: source,
              state: state
            })
          }
        }));
      });
      onSelect(function (_ref6) {
        var state = _ref6.state;
        var redirects = getRedirectData({
          state: state
        });
        if (redirects.length > 0) {
          setIsOpen(true);
        }
      });
    },
    reshape: function reshape(_ref7) {
      var _data, _state$context$redire3;
      var state = _ref7.state,
        sourcesBySourceId = _ref7.sourcesBySourceId;
      var redirects = (_data = (_state$context$redire3 = state.context.redirectUrlPlugin) === null || _state$context$redire3 === void 0 ? void 0 : _state$context$redire3.data) !== null && _data !== void 0 ? _data : [];
      redirects.forEach(function (redirect) {
        var source = sourcesBySourceId[redirect.sourceId];
        if (source === undefined) {
          return;
        }
        sourcesBySourceId[redirect.sourceId] = _objectSpread(_objectSpread({}, source), {}, {
          getItems: function getItems() {
            return source.getItems().filter(function (item) {
              if (source.getItemInputValue.__default) {
                process.env.NODE_ENV !== 'production' ? warn(false, "The source ".concat(JSON.stringify(source.sourceId), " does not have a `getItemInputValue` function. It's required to be able to filter out the redirect item.") + '\nSee https://www.algolia.com/doc/ui-libraries/autocomplete/core-concepts/sources/#param-getiteminputvalue') : void 0;
                return true;
              }
              var itemInputValue = source.getItemInputValue({
                item: item,
                state: state
              });
              if (itemInputValue === undefined) {
                process.env.NODE_ENV !== 'production' ? warn(false, "The source ".concat(JSON.stringify(source.sourceId), " does not return a string from the `getItemInputValue` function. It's required to be able to filter out the redirect item.") + '\nSee https://www.algolia.com/doc/ui-libraries/autocomplete/core-concepts/sources/#param-getiteminputvalue') : void 0;
                return true;
              }
              return itemInputValue.toLowerCase() !== state.query.toLowerCase();
            });
          }
        });
      });
      var redirectSource = {
        sourceId: 'redirectUrlPlugin',
        templates: templates,
        getItemUrl: function getItemUrl(_ref8) {
          var item = _ref8.item;
          return item.urls[0];
        },
        onSelect: function onSelect(_ref9) {
          var event = _ref9.event,
            item = _ref9.item,
            state = _ref9.state,
            navigator = _ref9.navigator;
          onRedirect([item], {
            event: event,
            navigator: navigator,
            state: state
          });
        },
        getItemInputValue: function getItemInputValue() {
          return state.query;
        },
        onActive: function onActive() {},
        getItems: function getItems() {
          return getRedirectData({
            state: state
          });
        }
      };
      process.env.NODE_ENV !== 'production' ? warn(sourcesBySourceId.redirect === undefined, 'A source with `sourceId: "redirect"` already exists. This source will be overridden.') : void 0;
      return {
        sourcesBySourceId: _objectSpread({
          // Our source has templates, but reshape only accepts sources without templates (autocomplete-core)
          redirect: redirectSource
        }, sourcesBySourceId),
        state: state
      };
    },
    onSubmit: function onSubmit(_ref10) {
      var event = _ref10.event,
        navigator = _ref10.navigator,
        state = _ref10.state;
      onRedirect(getRedirectData({
        state: state
      }), {
        event: event,
        navigator: navigator,
        state: state
      });
    },
    __autocomplete_pluginOptions: options
  };
}